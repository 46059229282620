#clockdiv {
  font-family: sans-serif;
  color: #fff;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
}

#clockdiv > div {
  margin: 2px;
  padding: 10px;
    background: linear-gradient(267.91deg, #a74300 -49.35%, #ff2b06 86.48%);
  border-radius: 8px;
  color: #fafafa;
  display: inline-block;
  backdrop-filter: blur(10px); /* Adjust the blur value as needed for the glass effect */
}

#clockdiv div > span {
  padding: 15px;
  border-radius: 3px;
  background: rgba(255, 43, 6, 0.7); /* Slightly darker shade for contrast */
  display: inline-block;
}

.smalltext {
  padding-top: 5px;
  font-size: 16px;
  color: white; /* TEDx red for the text */
}
