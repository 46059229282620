.tLayout {
  background-color: white;
  section.tLayout-section {
    min-height: 100vh;
    padding-left: 330px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 1080px) {
    section.tLayout-section {
      padding-left: 220px;
      padding-top: 10px;
    }
  }
  @media screen and (max-width: 700px) {
    section.tLayout-section {
      padding-left: 20px;
      padding-top: 70px;
    }
  }
}
