.contactCard {
  // background: linear-gradient(
  //   93.58deg,
  //   rgba(255, 43, 6, 0.3) 0%,
  //   rgba(53, 53, 53, 0.3) 30.22%,
  //   rgba(255, 43, 6, 0.3) 100%
  // );
  background: linear-gradient(267.91deg, #a74300 -49.35%, #660408 86.48%);
  border-radius: 8px;
  color: #fafafa;

  b {
    font-size: 1.25rem;
  }
}
